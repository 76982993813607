<template>
  <div class="rate-container">
    <div class="rate-btn-container">
      <div @mouseleave="handleRateLeave()" @mouseenter="handleRateEnter()">
        <div :class='["rate-icons-box", showIcons ? "ease-in" : "ease-out"]'>
          <div class="rate-icon-tip-position">
            <div class="rate-icons-layout">
              <div v-for="item in rateIcons" :key="item.id" class="icon-box">
                <div class="icon-tip-box" :style="{ left: item.left + 'px' }"
                  :class="{ 'show-tip': currentRate.id === item.id }">
                  <div class="icon-tip">
                    {{ item.tip }}
                  </div>
                  <img :src="iconArrow" class="icon-arrow" />
                </div>
                <img :src="item.bigIconPath" class="normal-style" :class="{ 'active-style': currentRate.id === item.id }"
                  @mouseenter="handleRateIconEnter(item)" @mouseleave="handleRateIconLeave(item)"
                  @click="handleSelectRateIcon(item)" />
              </div>
            </div>
          </div>
        </div>
        <div class="hover-box" :style="{ width: '60px', height: '50px' }">
          <div v-if="selectedRate && selectedRate.id" class="icon-selected-box" @click="handleCancelSelect"
            :style="{ width: width, height: height }">
            <img :src="selectedRate.iconPath" class="icon-selected" />
          </div>

          <div v-else @click="handleCancelSelect" class="icon-selected-box" :style="{ width: width, height: height }">
            <img :src="iconSmile" class="rate-icon" />
          </div>

        </div>
      </div>
      <h3 class="rate-tip" v-if="showText">Rate this result</h3>
    </div>
  </div>
</template>
<script setup>
import rateIcon from "@/assets/rate.svg";
import iconSurprise from "@/assets/rate-icons/icon-surprise.svg";
import iconBigSurprise from "@/assets/rate-icons/icon-big-surprise.svg";
import iconBigNausea from "@/assets/rate-icons/icon-big-nausea.svg";
import iconNausea from "@/assets/rate-icons/icon-nausea.svg";
import iconBigDoubt from "@/assets/rate-icons/icon-big-doubt.svg";
import iconDoubt from "@/assets/rate-icons/icon-doubt.svg";
import iconBigLove from "@/assets/rate-icons/icon-big-love.svg";
import iconLove from "@/assets/rate-icons/icon-love.svg";
import iconArrow from "@/assets/rate-icons/icon_arrow.svg";
import iconSmile from '@/assets/rate-icons/icon_smile.svg'
import { ref, defineEmits, onMounted, defineProps, watchEffect, watch } from "vue";
const props = defineProps({
  clearRate: Boolean,
  showText: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: '36px'
  },
  height: {
    type: String,
    default: '36px'
  },
  currentRateId: Number
});

const emits = defineEmits(["changeRate"]);

const rateIcons = [
  {
    id: 1,
    iconPath: iconSurprise,
    bigIconPath: iconBigSurprise,
    tip: "Amazing",
    left: -3,
  },
  {
    id: 2,
    iconPath: iconLove,
    bigIconPath: iconBigLove,
    tip: "Good",
    left: 65,
  },

  {
    id: 3,
    iconPath: iconDoubt,
    bigIconPath: iconBigDoubt,
    tip: "Doubt",
    left: 125,
  },

  {
    id: 4,
    iconPath: iconNausea,
    bigIconPath: iconBigNausea,
    tip: "Spit",
    left: 190,
  },
];

const currentRate = ref({});
const selectedRate = ref(null);
const showIcons = ref(false);

watch(() => props.currentRateId, (value) => {
  if (value) {
    rateIcons.forEach(item => {
      if (value === item.id) {
        selectedRate.value = item;
      }
    })
  } else {
    selectedRate.value = {};
  }
}, {
  immediate: true,
  deep: true
})

watchEffect(() => {
  if (props.clearRate == true) {
    currentRate.value = {};
    selectedRate.value = {};
  }
});

const handleClearRate = () => {
  currentRate.value = {};
  selectedRate.value = {};
};

defineExpose({
  handleClearRate,
});

let rateTimer = null;
const handleRateEnter = () => {
  clearTimeout(rateTimer);
  showIcons.value = true;
};

const handleRateLeave = () => {
  rateTimer = setTimeout(() => {
    showIcons.value = false;
  }, 1000);
};
const handleRateIconEnter = (item) => {
  currentRate.value = item;
};

const handleRateIconLeave = () => {
  currentRate.value = {};
};
const handleSelectRateIcon = (item) => {
  //数据上报
  selectedRate.value = item;
  showIcons.value = false;
  emits("changeRate", item);
};
const handleCancelSelect = () => {
  // currentRate.value = {}
  // selectedRate.value = {}
};
</script>

<style lang="less">
.rate-container {
  position: relative;
  // z-index: 999;

}

.rate-btn-container {
  .rate-icon {
    cursor: pointer;
  }

  .ant-btn {
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    height: 42px;
    width: 130px;
  }

  .rate-tip {
    font-family: 'Inter', Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #969696;
    margin-top: 8px;
  }
}

.rate-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hover-box {
    width: 100%;
    height: 100%;
    padding-top: 18px;
    display: flex;
    justify-content: center;
  }
}

.rate-icons-box {
  position: absolute;
  width: 245px;
  height: 54px;
  left: 50%;
  margin-left: -122.5px;
  z-index: 999;
  transition: opacity .2s, top .2s;
}

.rate-icons-box.ease-in {
  opacity: 1;
  top: -50px;
  pointer-events: all;
}

.rate-icons-box.ease-out {
  opacity: 0;
  top: -80px;
  pointer-events: none;
}

.rate-icons-layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  background: rgba(37, 37, 37, 0.9);
  border-radius: 600px;
  // animation: icons-animation 0.1s 1;
}



.normal-style {
  width: 42px;
  height: 42px;
  cursor: pointer;
}

.active-style {
  transform: scale(1.62);
  transition: 0.05s;
}

.emoji-tip {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.overlayStyle {
  border-radius: 4px;
  margin-bottom: 20px;

  .ant-tooltip-inner {
    border-radius: 4px;
  }
}

.icon-selected {
  width: 24px;
  height: 24px;
}

.icon-selected-box {
  // width: 36px;
  // height: 36px;
  border: 1px solid #d0d0d0;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rate-icon-tip-position {
  position: relative;
  width: 100%;
  height: 100%;
}

.icon-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  animation: icons-animation 0.2s ease-in;
  animation-timing-function: ease-in
}

@keyframes icons-animation {
  0% {
    margin-top: 12px;
  }

  100% {
    margin-top: 0;
  }
}


.icon-tip-box {
  position: absolute;
  top: -51px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 12px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  opacity: 0;

  .icon-arrow {
    position: absolute;
    bottom: -16px;
    // left: 36px;
  }
}

.show-tip {
  opacity: 1;
  animation: show-tip-animation 0.05s 1;
}

@keyframes show-tip-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.icon-tip {
  color: #ffffff;
}
</style>
